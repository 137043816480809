<template>
  <div class="subject">
    <div class="content">
      <ModTit
        :title="module.langNamesObj[langNamesEnum[languageActive]]"
        :moreText="$t('XB_More')"
        more="/subject"
        v-if="module.configuration.titleSetting.isShow"
      />
      <div class="card">
        <template v-if="dataSource.length">
          <a
            class="item"
            v-for="(item, index) in dataSource"
            :key="index"
            :href="`/subject/detail?id=${item.taskId}&ddtab=true`"
            target="_blank"
          >
            <div class="label">
              <span v-if="item.labelId == 3"
                >{{ $t("Home.Newly")
                }}<!-- 新上架 --></span
              >
              <div class="img" v-else>
                <img
                  v-if="item.labelId == 1"
                  :src="require('@/assets/image/subject_new.png')"
                  alt="new"
                />
                <img
                  v-if="item.labelId == 2"
                  :src="require('@/assets/image/subject_hot.png')"
                  alt="hot"
                />
              </div>
            </div>
            <div
              class="cover"
              :style="{ backgroundImage: 'url(' + item.coverImg + ')' }"
            ></div>
            <div class="info">
              <div class="title">{{ item.taskName }}</div>
            </div>
          </a>
        </template>
        <div class="empty-box" v-else>
          <a-empty
            :image="require('@/assets/image/empty.png')"
            :image-style="{
              height: '144px',
            }"
          >
            <template #description>
              <div class="empty-tips">{{ $t("Pub_Lab_NoData") }}</div>
            </template>
          </a-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModTit from "./ModTit.vue";
export default {
  name: "Subject",
  components: {
    ModTit,
  },
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    langNamesEnum: {
      type: Object,
      default: () => {
        return {};
      },
    },
    languageActive: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style lang="less" scoped>
.subject {
  .content {
    max-width: 1200px;
    margin: 0 auto;
    .card {
      .mixinFlex();
      flex-wrap: wrap;
      .item {
        overflow: hidden;
        background-color: #fff;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(148, 148, 148, 0.1);
        position: relative;
        transition: all 0.3s ease;
        width: calc((100% - 20px * (4 - 1)) / 4);
        margin-right: 20px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 6px 20px rgba(148, 148, 148, 0.15);
          .info {
            .title {
              color: @color-theme;
            }
          }
        }
        .label {
          position: absolute;
          left: 8px;
          top: 8px;
          z-index: 1;
          .img {
            .mixinImgWrap(28px; 28px);
          }
          span {
            height: 28px;
            padding: 0 8px;
            border-radius: 28px;
            background-color: #fff;
            color: #22cf33;
            font-size: 14px;
            line-height: 28px;
          }
        }
        .cover {
          background-size: 100% 100%;
          background-position: center;
          width: 100%;
          height: 148px;
        }
        .info {
          padding: 16px;
          .title {
            color: #333;
            font-size: 16px;
            font-weight: 500;
            .mixinEllipsis(52px, 2);
            line-height: 26px;
          }
        }
      }
    }
  }
}
.portal-col-70 {
  .subject {
    .content {
      .card {
        .item {
          width: calc((100% - 20px * (3 - 1)) / 3);
          margin-right: 20px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .cover {
            width: 100%;
            height: 142px;
          }
          .info {
            padding: 14px;
          }
        }
      }
    }
  }
}
.portal-col-50 {
  .subject {
    .content {
      .card {
        .item {
          width: calc((100% - 20px * (2 - 1)) / 2);
          margin-right: 20px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          .cover {
            width: 100%;
            height: 148px;
          }
          .info {
            padding: 16px;
          }
        }
      }
    }
  }
}
.portal-col-30,
.portal-col-33,
.portal-col-35 {
  .subject {
    .content {
      .card {
        .item {
          width: calc((100% - 10px * (2 - 1)) / 2);
          margin-right: 10px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          .cover {
            width: 100%;
            height: 98px;
          }
          .info {
            padding: 10px;
          }
        }
      }
    }
  }
}
.empty-box {
  padding: 70px 0;
  width: 100%;
  .empty-tips {
    color: #999;
    font-size: 14px;
    margin-top: 15px;
  }
}
</style>
